// vendors
import React from 'react';
import PropTypes from 'prop-types';
import { Container, Wrapper, Sidebar, NonSidebar } from './WithSidebar.styles';

const WithSidebar = ({
  children,
  spaceVertical,
  spaceHorizontal,
  contentMin,
  renderSidebar,
  align,
  side,
  ...rest
}) => {
  return (
    <Container
      spaceVertical={spaceVertical}
      spaceHorizontal={spaceHorizontal}
      {...rest}
    >
      <Wrapper align={align} contentMin={contentMin}>
        <Sidebar side={side} contentMin={contentMin}>
          {renderSidebar}
        </Sidebar>

        <NonSidebar contentMin={contentMin}>{children}</NonSidebar>
      </Wrapper>
    </Container>
  );
};

WithSidebar.propTypes = {
  children: PropTypes.node.isRequired,
  spaceVertical: PropTypes.arrayOf(PropTypes.string),
  spaceHorizontal: PropTypes.arrayOf(PropTypes.string),
  contentMin: PropTypes.string,
  renderSidebar: PropTypes.node.isRequired,
  align: PropTypes.string,
  side: PropTypes.oneOf(['left', 'right']),
};

WithSidebar.defaultProps = {
  align: 'start',
  spaceVertical: [0],
  spaceHorizontal: [0],
  contentMin: '50%',
  side: 'left',
};

export default WithSidebar;
