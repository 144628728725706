import React from 'react';
import styled from 'styled-components';

import { hideVisually } from 'polished';
import colors from '../styles/colors';
import breakpoints from '../styles/breakpoints';
import breakpointsRange from '../utils/breakpointsRange';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Button from '../components/Button';
import WithSidebar from '../components/WithSidebar';
import { h1Style } from '../styles/global';
import vectorError404 from '../images/vectorError404.svg';

const Title = styled.p`
  ${h1Style};

  color: ${colors.mineShaft};
`;

const Container = styled(WithSidebar)`
  max-width: 1440px;
  margin: auto;

  ${breakpointsRange(
    [
      { prop: 'paddingTop', sizes: [68, 134] },
      { prop: 'paddingBottom', sizes: [88, 140] },
      { prop: 'paddingRight', sizes: [28, 40, 40, 120] },
      { prop: 'paddingLeft', sizes: [28, 40, 40, 120] },
    ],
    breakpoints.spacings
  )};
`;

const Icon = styled.img`
  min-width: 260px;
`;

const TextWrapper = styled.div`
  > *:last-child {
    margin-bottom: 0;
  }
`;

const StyledButton = styled(Button)`
  ${breakpointsRange(
    [{ prop: 'marginTop', sizes: [48, 68] }],
    breakpoints.fonts
  )};
`;

const NotFoundPage = () => (
  <Layout backgroundColor={colors.wildSand}>
    <SEO title='404' />

    <h1 css={hideVisually}>Erreur 404 - Page introuvable</h1>

    <Container
      contentMin='54%'
      spaceVertical={[56, 104]}
      spaceHorizontal={[56, 104]}
      renderSidebar={<Icon src={vectorError404} />}
    >
      <TextWrapper>
        <Title>Oh! Nous ne pouvons trouver la page que vous cherchez.</Title>

        <p>
          Erreur 404 — Il est possible que cette page ait été déplacée, renommée
          ou supprimée.
        </p>

        <StyledButton to='/'>Retour à l’accueil</StyledButton>
      </TextWrapper>
    </Container>
  </Layout>
);

export default NotFoundPage;
