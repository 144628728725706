// vendors
import styled, { css } from 'styled-components';
import breakpoints from '../../styles/breakpoints';
import responsiveStyle from '../../utils/responsiveStyle';

export const Container = styled.div`
  overflow: hidden;

  ${({ spaceHorizontal }) =>
    responsiveStyle(
      '--spacehorizontal',
      spaceHorizontal,
      breakpoints.spacings
    )};

  ${({ spaceVertical }) =>
    responsiveStyle('--spacevertical', spaceVertical, breakpoints.spacings)};
`;

export const Sidebar = styled.div`
  ${({ contentMin, side }) =>
    css`
      flex-basis: calc((100% - ${contentMin}) - var(--spacehorizontal));

      order: ${side === 'right' ? 1 : 0};
    `}
  flex-grow: 1;
`;

export const NonSidebar = styled.div`
  flex-basis: 0;
  flex-grow: 999;
  ${({ contentMin }) =>
    css`
      min-width: calc(${contentMin} - var(--spacehorizontal));
    `}
`;

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: ${({ align }) => align};

  margin-top: calc(var(--spacevertical) / 2 * -1);
  margin-right: calc(var(--spacehorizontal) / 2 * -1);
  margin-bottom: calc(var(--spacevertical) / 2 * -1);
  margin-left: calc(var(--spacehorizontal) / 2 * -1);

  > * {
    margin-top: calc(var(--spacevertical) / 2);
    margin-right: calc(var(--spacehorizontal) / 2);
    margin-bottom: calc(var(--spacevertical) / 2);
    margin-left: calc(var(--spacehorizontal) / 2);
  }
`;
